const NotFoundCirclesSVG = () => {
  return (
    <svg
      width="537"
      height="497"
      className="scene"
      viewBox="0 0 537 497"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M304.026 496.017C551.654 480.438 566.925 332.871 505.847 264.775C444.769 196.678 419.713 65.5793 288.938 34.1606C158.162 2.74202 24.2441 51.127 6.63824 195.025C-10.9677 338.923 56.3985 511.597 304.026 496.017Z"
        pathdataid="M304.026 496.017C551.654 480.438 566.925 332.871 505.847 264.775C444.769 196.678 419.713 65.5793 288.938 34.1606C158.162 2.74202 24.2441 51.127 6.63824 195.025C-10.9677 338.923 56.3985 511.597 304.026 496.017Z"
        fill="#FCF7F3"
      />
      <path
        d="M49.7735 165.931C-66.8941 384.986 50.9067 475.427 141.018 459.147C231.129 442.868 356.053 490.325 451.469 395.444C546.885 300.563 575.91 161.128 462.462 70.6234C349.015 -19.8811 166.441 -53.124 49.7735 165.931Z"
        pathid=""
        stroke="#FFA489"
        strokeWidth="2"
      />
    </svg>
  );
};
export default NotFoundCirclesSVG;
