import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';

import { getNavigation } from '@/services/navigation';
import { Container } from '@/components/flexboxgrid';
import { PrimaryButton } from '@/components/core/button';
import {
  MainWrapper,
  ContentWrapper,
  SectionWrapper,
  DescriptionWrapper,
  ImageWrapper,
} from '@/components/core/static-pages';
import bgAnimated from '@/components/ui/bg-animated';
import NotFoundCirclesSVG from '@/components/ui/bg-animated/404-circles';
import withPageBanner from '@/components/core/withPageBanner';

import theme from '@/utils/styles-variables';

const Page404 = () => {
  useEffect(() => {
    const desktop = window.innerWidth > theme.breakpoints.max_lg;
    if (desktop) {
      bgAnimated();
    }
  }, []);

  return (
    <MainWrapper>
      <Container>
        <SectionWrapper>
          <ContentWrapper>
            <h2>Oops!</h2>
            <h1>{`That page doesn't exist.`}</h1>

            <DescriptionWrapper>
              <p>{`The page you are looking for doesn't exist or has been moved.`}</p>
              <p>Head to our homepage.</p>
            </DescriptionWrapper>

            <PrimaryButton href="/">Go back home</PrimaryButton>
          </ContentWrapper>

          <ImageWrapper>
            <NotFoundCirclesSVG />
          </ImageWrapper>
        </SectionWrapper>
      </Container>
    </MainWrapper>
  );
};

export const getStaticProps = withPageBanner(async () => {
  try {
    const navigation = await getNavigation();

    return {
      props: {
        navigation,
      },
      revalidate: 120,
    };
  } catch (error) {
    Sentry.captureException(error);

    return {
      props: {
        navigation: null,
      },
      revalidate: 120,
    };
  }
});

Page404.primaryLogo = true;

export default Page404;
